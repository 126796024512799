import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl, publicCoverDir } from '../../../config/constant';
import UploadBredcumb from './UploadBredcumb';
import ToastMessage from '../../common/tostMessage';
import { fileNameGenerator } from '../../common/helper';
import { uploadToB2 } from '../../common/uploadFileBucket';

const MusicDetails = () => {
        let navigate = useNavigate()
        const userLogin = useSelector((state) => state.userLogin)
        const { UserInfo } = userLogin
        const [coverImagePreview, setCoverImagePreview] = useState(null)
        const [coverImage, setCoverImage] = useState(null)
        const [coverImageErr, setCoverImageErr] = useState("")
        const [songTitle, setSongTitle] = useState("")
        const [songArtist, setSongArtist] = useState("")
        const [songLanguage, setSongLanguage] = useState("TAMIL")
        const [languageList, setLanguageList] = useState([])
        const [songTags, setSongTags] = useState("")
        const [songTagArray, setSongTagArray] = useState([])
        const [songAgree, setSongAgree] = useState(false)
        const [isValidated, setIsValidated] = useState(false)
        const [loading, setLoading] = useState(false)
        useEffect(() => {
                setLoading(true)


                const userId = UserInfo._id
                //console.log(formDatas)
                var local = JSON.parse(localStorage.getItem("UserInfo"))
                var t1 = local['token']

                const config = {
                        headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${t1}`,
                        },
                }
                axios.post(
                        baseUrl + 'api/web/getuserdata',
                        {
                                userId,
                        },
                        config
                ).then(res => {


                        if (res.data.songDraft != null && res.data.songDraft.isDrafted) {

                                if (res.data.songDraft.coverDest != undefined && res.data.songDraft.coverDest != null) {
                                        setCoverImagePreview(res.data.S3URL + res.data.songDraft.coverDest + res.data.songDraft.coverPic)
                                        setSongAgree(true)
                                        setIsValidated(true)
                                }
                                setSongTitle(res.data.songDraft.songTitle)
                                setSongArtist(res.data.songDraft.songArtist)
                                setSongLanguage(res.data.songDraft.songLanguage)
                                setLanguageList(res.data.songLanguage)
                                setSongTags(res.data.songDraft.songTagArray)
                                if (res.data.songDraft.songTagArray != undefined) {
                                        setSongTagArray(res.data.songDraft.songTagArray)
                                }
                        } else {
                                navigate("/musicupload");
                        }

                }).catch(err => {
                        console.log(err)
                })

                setLoading(false)

        }, [UserInfo]);

        const submitHandler = async () => {

                if (songTitle == "" || songArtist == "") { ToastMessage({ msg: "Please fill all fields", mode: 'error' }); return };


                const params = { songTitle, songArtist, userId: UserInfo._id, songTagArray };

                if (songLanguage == "undefined" || songLanguage == undefined) { params.songLanguage = 'TAMIL' } else { params.songLanguage = songLanguage };

                var axiosUrl = baseUrl + "api/web/musicdetailsupload";
                var local = JSON.parse(localStorage.getItem("UserInfo"))
                var t1 = local['token']
                const config = {
                        headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${t1}`,
                        },
                }

                axios.post(axiosUrl, params, config).then(res => {

                        if (res.data.status === 1) {
                                setIsValidated(true)
                                navigate('/lyricsupload')
                        }
                        else {
                                ToastMessage({ msg: res.data.message, mode: 'error' });
                                return;
                        }
                }).catch(err => {
                        console.log('res3');

                })

                /* if (coverImage != null && songTitle != "" && songArtist != "" && songAgree) {
                        var formData = new FormData()

                        songTagArray.forEach((item) => formData.append("songTagArray[]", item))

                        var local = JSON.parse(localStorage.getItem("UserInfo"))
                        var t1 = local['token']
                        const config = {
                                headers: {
                                        'Content-Type': 'multipart/form-data',
                                        "Authorization": `Bearer ${t1}`,
                                },
                        }
                        axios.post(baseUrl + "api/web/musicdetailsupload", formData, config).then(res => {
                                setLoading(false)
                                console.log(res.data)
                                if (res.data.status == 1) {
                                        setIsValidated(true)
                                        navigate('/lyricsupload')
                                }
                                else {
                                        //console.log(res)
                                        //alert(res.data.message)
                                }
                        }).catch(err => {
                                setLoading(false)
                                setCoverImageErr("Failed to upload")
                        })
                } */


        }

        const onDrop = useCallback(async (acceptedFiles) => {
                const file = acceptedFiles[0];

                if (file === null) { ToastMessage({ msg: "File not support.", mode: 'error' }); return };

                // Validate file type (png, jpg, jpeg)
                const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
                if (!allowedTypes.includes(file.type)) {
                        ToastMessage({ msg: "Only PNG, JPG, and JPEG files are allowed.", mode: 'error' });
                        return;
                }

                // Validate file size (e.g., max 5 MB)
                if (file.size > 2 * 1024 * 1024) { // 2 MB = 2 * 1024 * 1024 bytes
                        ToastMessage({ msg: "File size must be less than 2 MB.", mode: 'error' });
                        return;
                }

                setLoading(true);

                const params = { userId: UserInfo._id };

                if (file !== null) {
                        let tempFilename = await fileNameGenerator({ filename: file.name });
                        let fileName = `${publicCoverDir}${tempFilename}`;
                        let renamedFile = new File([file], tempFilename, { type: file.type });
                        let fileUrl = await uploadToB2(
                                renamedFile,
                                fileName,
                                tempFilename
                        );
                        if (fileUrl) {
                                params.file = tempFilename
                        } else {
                                ToastMessage({ msg: "Something Went wrong. Please try again.", mode: 'error' });
                                return;
                        }
                }



                var axiosUrl = baseUrl + "api/web/musicuploadcoverpic";

                const config = {
                        headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('UserInfo')).token}`,
                        },
                }

                axios.post(axiosUrl, params, config).then(res => {

                        if (res.data.status === 1) {
                                setCoverImageErr("");
                                setCoverImagePreview(res.data.coverPicHost + res.data.converImage);
                                setLoading(false);

                        }
                        else {
                                ToastMessage({ msg: res.data.message, mode: 'error' });
                                setCoverImageErr("")
                                setLoading(false);
                        }
                }).catch(err => {
                        ToastMessage({ msg: "Something went wrong.", mode: 'error' });
                        setLoading(false);
                });
                return true;

        }, [UserInfo])
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'image/jpg, image/jpeg,image/png' })
        return (<>
                <UploadBredcumb activeStep={2} />
                <div className="container musicDetailsBase">
                        <div className="text-center my-5 songUpload">
                                <h1>Enter Song Details</h1>
                                <div className="col songUploadInner py-4">
                                        <div className="upload-container text-center">
                                                <div className="col" {...getRootProps()}>
                                                        <label htmlFor="upload-photo" className="songUpload">
                                                                <img className='uploadImg' src={(coverImagePreview == null || coverImagePreview == " ") ? "images/music.png" : coverImagePreview} alt="Upload" /></label>
                                                        <input type="file" name="photo" /* id="upload-photo" */   {...getInputProps({ className: 'dropzone' })} />
                                                        {
                                                                isDragActive ?
                                                                        <p className='f-bold mt-3'>Drop the files here ...</p> :
                                                                        <p className='f-bold mt-3'>Upload Album Art *</p>
                                                        }
                                                </div>
                                                <span className="error">
                                                        {coverImageErr}
                                                </span>

                                                <div className="form-floating text-start mt-5 mb-3">
                                                        <input type="text" className="form-control" id="title" placeholder="Title "
                                                                value={songTitle}
                                                                onChange={(e) => setSongTitle(e.target.value)} />
                                                        <label htmlFor="title">Title*</label>
                                                </div>
                                                <div className="form-floating text-start mb-3">
                                                        <input type="text" className="form-control" id="artist" placeholder="Artist "
                                                                value={songArtist}
                                                                onChange={(e) => setSongArtist(e.target.value)}
                                                        />
                                                        <label htmlFor="artist">Artist*</label>
                                                </div>
                                                <div className="form-floating text-start mb-3">
                                                        <select
                                                                className='select-control  langInp p-3'
                                                                value={songLanguage}
                                                                onChange={(e) => {
                                                                        setSongLanguage(e.target.value)
                                                                }}
                                                        >
                                                                {
                                                                        (() => {
                                                                                let container = [];
                                                                                if (languageList != null)
                                                                                        languageList.forEach((val) => {
                                                                                                container.push(
                                                                                                        <option value={val}>{val}</option>)
                                                                                        });
                                                                                return container;
                                                                        })()
                                                                }
                                                        </select>
                                                </div>
                                                <div className="form-floating text-start mb-3">
                                                        <input type="text" className="form-control" id="tags" placeholder="Tags"
                                                                value={songTags}
                                                                onChange={(e) => {
                                                                        const data = e.target.value.split(",")
                                                                        //console.log(data)
                                                                        setSongTagArray(data)
                                                                        setSongTags(e.target.value)
                                                                }}
                                                        />
                                                        <label htmlFor="tags">Tags</label>
                                                </div>
                                                <div className="form-floating text-start mb-3">
                                                        {songTagArray.map(name => (
                                                                <span key={name} className="tagColor me-2" onClick={(e) => {
                                                                        var data = songTagArray.filter(function (element) {
                                                                                return element != name;
                                                                        });
                                                                        //console.log(data)
                                                                        setSongTagArray(data)
                                                                        var jointags = data.join()
                                                                        setSongTags(jointags)
                                                                }}>
                                                                        {name}
                                                                </span>
                                                        ))}


                                                </div>
                                                <div className="normal_custom_check text-start">
                                                        <label className="control control--checkbox">
                                                                <input type="checkbox"
                                                                        // defaultChecked={songAgree}
                                                                        checked={songAgree}
                                                                        /* checked={songAgree} */
                                                                        onChange={(e) => {
                                                                                //console.log.apply(e.target.checked)
                                                                                setSongAgree(e.target.checked.toString())
                                                                        }} />
                                                                I agree that song complies with YoYo Singer terms and service and does not infringe else's right
                                                                <div className="control__indicator"></div>
                                                        </label>
                                                </div>

                                        </div>
                                        <div className="clearfix"></div>

                                </div>
                                <div className=" pt-3 d-flex flex-row justify-content-between ">
                                        <Link to="/musicupload" className="submitBtn float-start"> Back </Link>

                                        {!loading ?
                                                <div /* to="/lyricsupload" */ className="submitBtn float-end cursor-pointer"
                                                        onClick={(e) => {
                                                                e.preventDefault();
                                                                submitHandler();


                                                        }}>Next </div> : <Spinner animation="grow" />}
                                </div>
                                <p className="mt-5 text-start"><sup>*</sup>  By uploading this song, you confirm that you are complying with all legal guidelines set forth by our Community Guidelines and Terms of Service.If you have any questions, please refer to our FAQ or contact YoYo Singer for more assistance.</p>
                        </div>
                </div></>
        )
}

export default MusicDetails
