'use server';
import { serverUrl } from "../../config/constant";
import axios from "axios";

/**
 * Upload a file to Backblaze B2 using the S3-compatible API.
 * @param {File} file - The file to upload.
 * @param {string} bucketName - The name of the B2 bucket.
 * @param {string} accessKeyId - Your Backblaze access key ID.
 * @param {string} secretAccessKey - Your Backblaze secret access key.
 * @param {string} region - The B2 region (default: "us-west-002").
 * @returns {Promise<string>} - The URL of the uploaded file.
 */
export const uploadToB2 = async (file, fileName, tempFilename) => {


    try {
        const sigednUrl = await axios.post(`${serverUrl}api/generatepresignedurl`, {
            fileType: file.type,
            fileName: fileName
        });
        if (sigednUrl.status === 201) {

            const preSignedUrl = sigednUrl.data.uploadUrl;
            const uploadResponse = await axios.put(preSignedUrl, file, {
                headers: {
                    "Content-Type": file.type,
                },
            });
            console.log(uploadResponse);
            if (uploadResponse.status === 200) {
                return true;
            } else {
                return false;
            }

        } else {
            return false;
        }

    } catch (e) {
        console.log(e);
        return false;
    }




};

