import { toast } from "react-toastify";

const ToastMessage = ({
    mode = "success",
    msg,
    position = "top-right",
    autoClose = 5000,
    hideProgressBar = false,
    closeOnClick = false,
    pauseOnHover = true,
    draggable = true,
    progress = undefined,
    theme = "light",
}) => {
    const commonOptions = {
        position,
        autoClose,
        hideProgressBar,
        closeOnClick,
        pauseOnHover,
        draggable,
        progress,
        theme,
    };

    if (mode === "success") {
        toast.success(msg, commonOptions);
    } else {
        toast.error(msg || "Failed to action.", commonOptions);
    }
};

export default ToastMessage;
