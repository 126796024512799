import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import waveImg from '../../../assets/images/myImages/digital-wave-audio.svg';
import { baseUrl, publicMusicDir } from '../../../config/constant';
import UploadBredcumb from './UploadBredcumb';
import ToastMessage from '../../common/tostMessage';
import { fileNameGenerator } from '../../common/helper';
import { uploadToB2 } from '../../common/uploadFileBucket';

const MusicUpload = () => {
        const navigate = useNavigate();
        const userLogin = useSelector((state) => state.userLogin);
        const { UserInfo } = userLogin;

        const [fileError, setFileError] = useState('');
        const [uploadFileName, setUploadFileName] = useState(null);
        const [loading, setLoading] = useState(false);
        const [uploadSuccess, setUploadSuccess] = useState(false);

        useEffect(() => {
                setLoading(true);
                const userId = UserInfo?._id;

                const config = {
                        headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${JSON.parse(localStorage.getItem('UserInfo')).token}`,
                        },
                };

                axios
                        .post(
                                `${baseUrl}api/web/getuserdata`,
                                { userId },
                                config
                        )
                        .then((res) => {
                                if (res.data.songDraft?.isDrafted) {
                                        setUploadSuccess(true);
                                        setUploadFileName(res.data.songDraft.originalname);
                                }
                        })
                        .catch((err) => console.error(err))
                        .finally(() => setLoading(false));
        }, [UserInfo]);


        const getAudioDuration = async (paramfile) => {
                return new Promise((resolve, reject) => {
                        const audio = new Audio(URL.createObjectURL(paramfile));
                        audio.onloadedmetadata = () => {
                                resolve(audio.duration); // duration in seconds
                        };
                        audio.onerror = () => {
                                reject(new Error("Failed to load audio metadata"));
                        };
                });
        };

        const onDrop = useCallback(
                async (acceptedFiles) => {
                        const file = acceptedFiles[0];

                        if (file === null) { ToastMessage({ msg: "Please upload file.", mode: 'error' }); return; };

                        // Validate file type
                        if (file.type !== "audio/mpeg") {
                                ToastMessage({ msg: "Only MP3 files are allowed.", mode: 'error' }); return;
                        }

                        // Validate file size (20 MB = 20 * 1024 * 1024 bytes)
                        if (file.size > 20 * 1024 * 1024) {
                                ToastMessage({ msg: "File size must be less than 20 MB.", mode: 'error' }); return;
                        }


                        try {
                                const duration = await getAudioDuration(file);
                                if (duration > 420) { // 10 minutes = 600 seconds
                                        ToastMessage({ msg: "Audio length exceeds 7 minutes.", mode: 'error' });
                                        return;
                                }
                        } catch (error) {
                                ToastMessage({ msg: "Error loading audio metadata.", mode: 'error' });
                                return;
                        }


                        setLoading(true);

                        const params = { userId: UserInfo._id, originalname: file.name };

                        if (file !== null) {
                                let tempFilename = await fileNameGenerator({ filename: file.name });
                                let fileName = `${publicMusicDir}${tempFilename}`;
                                let renamedFile = new File([file], tempFilename, { type: file.type });
                                let fileUrl = await uploadToB2(
                                        renamedFile,
                                        fileName,
                                        tempFilename
                                );
                                if (fileUrl) {
                                        params.file = tempFilename
                                } else {
                                        ToastMessage({ msg: "Something Went wrong. Please try again.", mode: 'error' });
                                        return;
                                }
                        }



                        var axiosUrl = baseUrl + "api/web/musicupload";

                        const config = {
                                headers: {
                                        'Content-Type': 'application/json',
                                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('UserInfo')).token}`,
                                },
                        }

                        axios.post(axiosUrl, params, config).then(res => {

                                if (res.data.status === 1) {
                                        setUploadFileName(res.data.name);
                                        setFileError('');
                                        setUploadSuccess(true);
                                        setLoading(false);

                                }
                                else {
                                        ToastMessage({ msg: res.data.message, mode: 'error' });
                                        setFileError(res.data.message);
                                        setUploadSuccess(false);
                                        setLoading(false);
                                }
                        }).catch(err => {
                                setFileError('Failed to upload');
                                setUploadSuccess(false);
                                setLoading(false);
                        })
                },
                [UserInfo]
        );

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
                onDrop,
                multiple: false,
                accept: 'audio/mpeg, audio/m4a',
        });

        return (
                <>
                        <UploadBredcumb activeStep={1} />
                        <div className="col p-0">
                                <div className="container musicUploadBase">
                                        <div className="text-center my-5 songUpload">
                                                <h1>Upload Song</h1>
                                                <div className="col songUploadInner py-4" {...getRootProps()}>
                                                        <h2>Upload a vocal track. Make sure your file doesn't include vocals.</h2>
                                                        <div className="upload-container text-center">
                                                                {!loading ? (
                                                                        <div>
                                                                                <label className="songUpload">
                                                                                        <img
                                                                                                src={uploadFileName ? waveImg : 'images/music.png'}
                                                                                                alt="Upload"
                                                                                        />
                                                                                </label>
                                                                                <input {...getInputProps()} />
                                                                                {fileError ? (
                                                                                        <span className="error d-inline-block w-100 mt-3">
                                                                                                {fileError}
                                                                                        </span>
                                                                                ) : (
                                                                                        <span className="d-inline-block w-100 mt-3">
                                                                                                {uploadFileName}
                                                                                        </span>
                                                                                )}
                                                                        </div>
                                                                ) : (
                                                                        <Spinner animation="grow" />
                                                                )}
                                                                {isDragActive && <p>Drop the files here ...</p>}
                                                        </div>
                                                        <h3 className="mt-4">
                                                                We support: mp3 file only. Max file size: 20 MB. Max length: 7 mins.
                                                        </h3>
                                                </div>
                                                {uploadSuccess && (
                                                        <div className="pt-3 d-flex flex-row justify-content-end">
                                                                <Button
                                                                        className="submitBtn"
                                                                        onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if (
                                                                                        fileError !== 'File Not Supported' &&
                                                                                        fileError !== 'Failed to upload' &&
                                                                                        uploadFileName
                                                                                ) {
                                                                                        navigate('/musicdetails');
                                                                                }
                                                                        }}
                                                                >
                                                                        Next
                                                                </Button>
                                                        </div>
                                                )}
                                                <p className="mt-5">
                                                        <sup>*</sup> By uploading this song, you confirm that you are complying
                                                        with all legal guidelines set forth by our Community Guidelines and
                                                        Terms of Service. If you have any questions, please refer to our FAQ or
                                                        contact YoYo Singer for more assistance.
                                                </p>
                                        </div>
                                </div>
                        </div>
                </>
        );
};

export default MusicUpload;
