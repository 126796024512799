/* export const baseUrl = "https://yoyosinger.com/"
export const serverUrl = "https://yoyosinger.com/"
export const androidAppLink = "https://yoyosinger.com/"
export const awardUrl = "https://yoyosinger.com/" */

export const baseUrl = "https://yoyosinger.com/"
export const serverUrl = "https://yoyosinger.com/"
export const androidAppLink = "https://yoyosinger.com/"

export const awardUrl = "https://yoyosinger.com/"


export const iosAppLink = "https://yoyosinger.com/";
export const profilePicDir = "uploads/profilePic/";
export const strickerUrl = "uploads/sticker/";
export const strickerCatUrl = "uploads/stickerCategory/";
export const logoUrl = "uploads/logo/";
export const fileUploadUrl = "uploads/files/"
export const bannersUrl = "uploads/banners/"
export const coverPicDir = "uploads/coverPic/"
export const draftMusicDir = "uploads/draft/music/"
export const draftCoverDir = "uploads/draft/cover/"
export const publicMusicDir = "uploads/public/music/"
export const publicCoverDir = "uploads/public/cover/"
export const r_key_id = "rzp_test_s1IOHVXK96IJHN" //razerPay
export const r_key_secret = "ifDqRmvALu8efxQLuANF9A2N" //razerPay
export const one_signal_rest_api_key = "MDJiNTEzZGItMmNkNi00ZGM2LThiODQtMzE0MTRkZGRmMWUy" //one signal
export const one_signal_app_id = "1cdc2c73-a5b6-4ca5-a552-0dc901bd1c4c" //one signal
export const tinymceApiKey = "2ub85tpcwacfjqpvdauwo12cczkeoxo5v2w3lwp8eyq4sc9a"
export const voteLimit = 150



/* "proxy":"http://127.0.0.1:5000", */



export const s3EndPoint = "s3.eu-central-003.backblazeb2.com";
export const s3AccessKeyId = "003761eda43319f0000000002";
export const s3SecretAccessKey = "K003RHhZy7vbrVKSi9M0/ZuTtTO2+cc";
export const s3SignatureVersion = "v4";
export const s3ForcePathStyle = true;
export const s3BucketName = "yoyosingerdev";