import 'bootstrap/dist/js/bootstrap.min.js';
import './css/style.scss';
import "./scss/style.scss";

import Footer from './components/web/common/Footer';
import Header from './components/web/common/Header';
import Landing from './components/web/landing/Landing';

import { useDispatch } from "react-redux";
import './App.css';

import React, { Suspense, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import MusicUpload from './components/web/songupload/MusicUpload';


import LyricsTiming from './components/web/songupload/LyricsTiming';
import LyricsUpload from './components/web/songupload/LyricsUpload';
import MusicDetails from './components/web/songupload/MusicDetails';


import AdminLanding from './components/admin/AdminLanding/AdminLanding';

import Privacy from './components/web/pages/Privacy';
import Player from './components/web/user/Player';
import UserLanding from './components/web/user/UserLanding';


import RedirectToApp from './components/web/common/RedirectToApp';
import CommanPage from './components/web/pages/commanPage';
import AdminProtectedRoute from './protected/adminProtectedRoute';
import UserProtectedRoute from './protected/userProtectedRoute';
import { ToastContainer } from 'react-toastify';




//new-theme-layout
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

function App() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

  return (
    <Router>
      <Suspense fallback={loading}>
        {(!window.location.pathname.includes('/admin')) && <Header handleShow={() => handleShow()} handleClose={() => handleClose} >
        </Header>}
        <ToastContainer />
        <Routes>

          {/* adminlogin */}
          <Route exact path="/admin" element={<AdminLanding />} />
          <Route exact path="/admin/*" element={
            <AdminProtectedRoute>
              <DefaultLayout />
            </AdminProtectedRoute>
          } />
          <Route exact path="/app" element={<RedirectToApp />} />

          {/* userlogin */}
          <Route exact path="/" element={<Landing />} />

          <Route exact path="/musicupload" element={
            <UserProtectedRoute>
              <MusicUpload />
            </UserProtectedRoute>
          } />
          <Route exact path="/musicdetails" element={
            <UserProtectedRoute>
              <MusicDetails />
            </UserProtectedRoute>
          } />
          <Route exact path="/lyricsupload" element={
            <UserProtectedRoute>
              <LyricsUpload />
            </UserProtectedRoute>
          } />
          <Route exact path="/lyricstiming" element={
            <UserProtectedRoute>
              <LyricsTiming />
            </UserProtectedRoute>
          } />
          <Route exact path="/:invite/:songRef" element={
            <Player />
          } />
          <Route exact path="/mysongs" element={
            <UserProtectedRoute>
              <UserLanding />
            </UserProtectedRoute>
          } />

          {/*  new-version-react*/}
          <Route path="/privacy-policy" element={<Privacy pageSlug="privacyPolicy" />} />
          <Route path="/terms-of-use" element={<Privacy pageSlug="termsOfUse" />} />
          <Route path="/disclamier" element={<Privacy pageSlug="disclaimer" />} />
          <Route path="/about-us" element={<Privacy pageSlug="aboutUs" />} />

          <Route path="/page/:slug" element={<CommanPage />} />

          {/*  old-version-react*/}
          {/*  <Route exact path="/privacy-policy" element={() => <Privacy pageSlug={"privacyPolicy"} />} />
          <Route exact path="/terms-of-use" element={() => <Privacy pageSlug={"termsOfUse"} />} />
          <Route exact path="/disclamier" element={() => <Privacy pageSlug={"disclaimer"} />} />
          <Route exact path="/about-us" element={() => <Privacy pageSlug={"aboutUs"} />} /> */}
        </Routes>
        {(!window.location.pathname.includes('/admin')) && <Footer show={show} handleClose={() => handleClose()}></Footer>}
      </Suspense>
    </Router>
    /*   <Router >
         <Suspense fallback={loading}></Suspense>
        <div className="app">
  
          {(!window.location.pathname.includes('/admin')) && <Header handleShow={() => handleShow()} handleClose={() => handleClose} ></Header>}
  
  
          <Switch>
            <Route exact path="/" component={Landing} />
           <Route exact path="/musicupload" component={MusicUpload} />
            <Route path="/musicdetails" component={MusicDetails} exact />
            <Route path="/lyricsupload" component={LyricsUpload} exact />
            <Route path="/lyricstiming" component={LyricsTiming} exact />
            <Route exact path="/admin" component={AdminLanding} />
            <Route exact path="/mysongs" component={UserLanding} />
            <Route exact path="/admin/dashboard" component={AdminSidebar} />
            <Route exact path="/:invite/:songRef" component={Player} />
            <Route exact path="/terms-of-use" component={() => <Privacy pageSlug={"termsOfUse"} />} />
            <Route exact path="/privacy-policy" component={() => <Privacy pageSlug={"privacyPolicy"} />} />
            <Route exact path="/disclamier" component={() => <Privacy pageSlug={"disclaimer"} />} />
            <Route exact path="/about-us" component={() => <Privacy pageSlug={"aboutUs"} />} /> 
           
          </Switch>
           {(!window.location.pathname.includes('/admin')) && <Footer show={show} handleClose={() => handleClose()}></Footer>} 
        </div>
      </Router> */

  );
}

export default App;
